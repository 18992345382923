<template>
  <image-header-page :image-header-props="imageHeaderProps">
    <template v-slot:header>
      <div class="active-member">
        <profile-shape :shape="settingsVariant.image" :color="settingsVariant.colorName" />
        <div class="ri-large-headline margin-bottom-xxs margin-top-sm">{{ activeMember.firstNamePiiValue }}
          {{ activeMember.lastNamePiiValue }}
        </div>
        <div class="ri-large-body margin-bottom-xxs">{{ activeMember.emailPiiValue }}</div>
        <div class="ri-large-body margin-bottom-ml">{{ formatPhoneNumber(activeMember.phoneNumberPiiValue) }}</div>
        <action-button v-bind="editButtonProps" @click="navigate('PersonalInformation')" @keyup.enter="navigate('PersonalInformation')" />
      </div>
    </template>
    <template v-slot:overlayContent v-if="hasOtherMembers">
      <card class="members-card margin-bottom-l" :has-content-padding="false">
        <members-of-account :members="otherMembers"/>
      </card>
    </template>
    <template>
      <div class="menu-items">
        <navigation-button @navigate-clicked="navigate('AccountSettings')" button-text="הגדרות מינוי"
                           :icon-props="{ iconName: 'account_box', fill: true }"/>
        <navigation-button @navigate-clicked="navigate('CredentialsSettings')" button-text="חשבונות מחוברים"
                           :icon-props="{ iconName: 'payments', fill: true }"/>
        <navigation-button v-if="enableChangeCashflowStartDay" button-text="יום תחילת התזרים"
                           image-path="nav/cf-start-date.svg"
                           @navigate-clicked="navigate('CashflowStartDaySettings')"/>
        <navigation-button @navigate-clicked="navigate('TCSettings')" button-text="שינוי סדר קטגוריות "
                           :icon-props="{ iconName: 'low_priority', fill: true }" />
        <navigation-button @navigate-clicked="redirectToLogin" button-text="יציאה"
                           :icon-props="{ iconName: 'move_item', fill: true }" :show-expand="false"/>
      </div>
    </template>
  </image-header-page>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import Segment from '@/Segment';
import phoneNumberUtils from '@/utils/phoneNumber';
import NavigationButton from '@/base-components/app-header/NavigationButton';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import MembersOfAccount from '@/pages/responsive-pages/authenticated/navigation-pages/settings/MembersOfAccount';
import ProfileShape from '@/pages/responsive-pages/authenticated/navigation-pages/settings/ProfileShape';
import { settingsProfileVariants } from '@/pages/responsive-pages/authenticated/navigation-pages/settings/settings.consts';

export default {
  name: 'Settings',
  components: {
    MembersOfAccount,
    NavigationButton,
    ProfileShape,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    Card: BaseUI.Card,
    ActionButton: BaseUI.ActionButton,
  },
  data() {
    return {
      editButtonProps: {
        text: 'עריכת פרטים',
        backgroundColor: 'transparent',
        textColor: Colors.riseupWhite,
        showLeftArrow: false,
        overrideIconProps: {
          iconName: 'edit',
          fill: true,
          opticalSize: 24,
          size: '14px',
        },
      },
    };
  },
  async created() {
    Segment.trackUserGot('SettingsEntered');
    this.setAppHeaderProps({
      backgroundColorVariant: this.settingsVariant.colorName,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
    });
  },
  computed: {
    ...mapState('session', ['activeMember', 'members']),
    ...mapGetters('featureFlags', ['enableChangeCashflowStartDay']),
    imageHeaderProps() {
      return {
        textColor: Colors.riseupWhite,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.TOP,
        backgroundColor: this.settingsVariant.colorName,
        bottomSeparator: false,
        imgSrc: '',
        imgHeight: 340,
      };
    },
    settingsVariant() {
      const memberIndex = this.getMemberIndex(this.activeMember);
      return settingsProfileVariants[memberIndex ?? 0];
    },
    otherMembers() {
      return _.differenceBy(this.members, [this.activeMember], member => member.phoneNumberPiiValue);
    },
    hasOtherMembers() {
      return this.otherMembers.length > 0;
    },
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    formatPhoneNumber(phoneNumber) {
      return phoneNumberUtils.visualizePhoneNumber(phoneNumber);
    },
    async redirectToLogin() {
      await this.logout();
      this.closeModal();
    },
    navigate(name) {
      return router.push({
        name,
        query: this.$route.query,
      });
    },
    getMemberIndex(member) {
      return (_.chain(this.members)
        .sortBy(m => m.phoneNumberPiiValue)
        .findIndex({ phoneNumberPiiValue: member?.phoneNumberPiiValue })
        .value());
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.active-member {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $riseup_white;
}

.members-card {
  background: $riseup_white;
  color: $riseup_black;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-items {
  position: relative;
  padding: 0 25px;
  border-top: solid 1px $riseup_gray_0;
  border-bottom: solid 1px $riseup_gray_0;
}
</style>
