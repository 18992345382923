<template>
  <div class="margin-ml">
    <div class="ri-body margin-bottom-sm">לקוחות במינוי</div>
    <div v-for="(member, index) of members" :key="member.phoneNumberPiiValue">
      <member-of-account :name="member.firstNamePiiValue + ' ' + member.lastNamePiiValue"
                         :email="member.emailPiiValue"
                         :phone-number="formatPhoneNumber(member.phoneNumberPiiValue)"/>
      <separator v-if="index < members.length - 1" class="margin-top-sm margin-bottom-ml"/>
    </div>
  </div>
</template>

<script>
import phoneNumberUtils from '@/utils/phoneNumber';
import MemberOfAccount from '@/pages/responsive-pages/authenticated/navigation-pages/settings/MemberOfAccount';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'MembersOfAccount',
  components: {
    MemberOfAccount,
    Separator: BaseUI.Separator,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      return phoneNumberUtils.formatPhoneNumber(phoneNumber);
    },
  },
};
</script>

<style scoped>

</style>
