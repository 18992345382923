<template>
  <div class="member">
    <span class="ri-medium-bold-headline margin-bottom-xs"> {{name}}</span>
    <span v-if="email" class="ri-body margin-bottom-xxs">{{email}}</span>
    <span class="ri-body margin-bottom-xxs">{{phoneNumber}}</span>
  </div>
</template>

<script>
export default {
  name: 'MemberOfAccount',
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';

.member {
  color: $riseup_gray_2;
  display: flex;
  flex-direction: column;

  :first-child {
    color: $riseup_black;
  }
}
</style>
