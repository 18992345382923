<template>
  <div class="navigation-button">
    <div class="button-content" @click="$emit('navigate-clicked')">
      <div class="button-and-text">
        <div class="button-image-wrapper">
          <img v-if="imagePath" :src="`/images/${imagePath}`" :class="`image ${variant}`"/>
          <icon v-else v-bind="iconProps" weight="bold" fill :class="`image ${variant}`"/>
        </div>
        <span class="button-text">
        {{ buttonText }}
      </span>
      </div>
      <span v-if="showExpand" class="fa fa-chevron-left chevron"></span>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'NavigationButton',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      required: false,
    },
    iconProps: {
      type: Object,
      required: false,
    },
    showExpand: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'normal',
      validate: value => ['normal', 'large'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .navigation-button {
    height: 72px;
    font-size: 16px;
    cursor: pointer;
    &:not(:last-of-type) {
      border-bottom: solid 1px $riseup-gray_0;
    }

    .button-content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button-and-text {
        display: flex;
        align-items: center;
        .button-image-wrapper {
          color: $riseup_blue;
          position: relative;
          text-align: center;
          .image {
            &.normal {
              font-size: 24px;
              width: 32px;
            }
            &.large {
              font-size: 32px;
              width: 32px;
              height: 46px;
            }
          }
          .animation {
            position: absolute;
            top: 0;
            width: 32px;
            height: 32px;
          }
        }

        .button-text {
          margin-right: 14px;
          position: relative;
        }
      }
      .chevron {
        font-size: 10px;
      }
    }
  }
</style>
